.m0 {
  margin: 0;
}
.half-width {
  width: 90%;
}

.app-header {
  background-color: #282c34;
  color: white;
}

/* below 3 classes are used for split-view */
.split-view {
  margin-right: auto;
  margin-left: auto;
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}
.left {
  width: max(50vw, 300px);
  margin-left: auto;
  margin-right: 1rem;
  padding-right: 2rem;
  border-right: rgba(0, 0, 0, 0.1) 1px solid;
  overflow: hidden;
}
.right {
  width: max(40vw, 300px);
  margin-right: auto;
  margin-left: 1rem;
  margin-top: 4rem;
  overflow: hidden;
}
.middle {
  margin-left: auto;
  margin-right: auto;
}

.app-header-inner-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.signup-form-inner {
  width: -moz-fit-content;
  width: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  width: max(30vw, 300px);
}

.listings-wrapper {
  display: flex;
  flex-direction: column;
}

.listing {
  color: black;
  background-color: #f7f7f7;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  border-radius: 0.25rem;
}

.logs-listing-wrapper {
  display: flex;
  width: 80%;
}
.logs-listing {
  overflow: hidden;
  cursor: pointer;
  display: flex;
}

@media (max-width: 1200px) {
  .split-view {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
  .left {
    border-right: rgba(0, 0, 0, 0.1) 1px solid;
  }
}

@media (max-width: 960px) {
  .split-view {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
  .left {
    width: max(80vw, 300px);
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    border-right: none;
  }
  .right {
    width: max(80vw, 300px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }
  .app-header-inner-wrapper {
    width: max(80vw, 300px);
  }
  .half-width {
    width: max(80vw, 300px);
  }
}

/* Below is all of the CSS grabbed from the custom table we are using */
body {
  color: #333;
  background: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
}
